(function () {
  'use strict';

  angular
    .module('neo.home.championships.championship.editMatches')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.championships.championship.editMatches', {
        url: '/edit-matches',
        templateUrl: 'home/championships/championship/edit-matches/edit-matches.tpl.html',
        controller: 'EditMatchesCtrl',
        controllerAs: 'vm',
        resolve: {
          sports: function (Sports) {
            return Sports.query().$promise;
          },
          categories: function (Categories) {
            return Categories.query().$promise;
          },
          genders: function (Genders) {
            return Genders.query().$promise;
          },
          towns: function (Poblacions) {
            return Poblacions.query().$promise;
          },
          facilities: function (Facilities) {
            return Facilities.query().$promise
          },
          championshipCalendar: function ($stateParams, ChampionshipCalendarPublic) {
            return ChampionshipCalendarPublic.get({id: $stateParams.championshipId}).$promise;
          },
          teams: function (InscribedTeams, $stateParams, championship) {
            return InscribedTeams.query({
              phaseId: championship.phaseId,
              categoryId: championship.categoryId,
              sportId: championship.sportId
            }).$promise;
          }
        }
      });
  }
}());
